<template>
    <div class="main">
        <Title >视频中心</Title>
        <div class="box">
            <div class="list col3">
                <video class="video" controls="controls" :src="require('../../assets/1.mp4')"></video>
                <p>水质采样器</p>
            </div>
            <div class="list col3">
                <video class="video" controls="controls" :src="require('../../assets/2.mp4')"></video>
                <p>毒品检测</p>
            </div>
        </div>
        <el-pagination
        background
        layout="prev, pager, next"
        :total="2">
        </el-pagination>
    </div>
</template>
<script>
export default {
    computed:{
        data(){
            return this.$store.state.lsData;
        }
    },
    methods:{
        detail(id){
            this.$router.push({
                path:'/news/ls_detail/'+id
            })
        }
    }
}
</script>
<style scoped src="../../layout.css"></style>
<style scoped>
.main{
    width:80vw;
    margin:0 auto;
    max-width:1600px;
}
.box{
    /* margin:2rem 0; */
    /* padding:0; */
    display:flex;
    flex-wrap:wrap;
    /* justify-content:space-around; */
}
.list{
    padding:1rem;
    /* width:50%; */
    /* height:276px; */
    box-sizing: border-box;
}
.video{
    /* max-height: 400px;
    height: 36vh;
    max-width: 300px; */
    width:100%;
    height:200px;
}
@media screen and ( max-width:1200px){
    /* .box{
        flex-wrap: wrap;
        justify-content: center;
    } */
}
</style>